








import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import FormuleBoosterLogo from './formule-booster-logo.vue';

@Component({
  components: {
    FormuleBoosterLogo,
  },
})
export default class FormuleBoosterSpinners extends Vue {
  @Prop({ type: String, default: 'dark' })
  public mode!: string;
}
