



















































import 'chartjs-plugin-style';

import { debounce } from '@bcase/core';
import { BlockElement } from '@bcase/module-editor';
import _ from 'lodash';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import Spinners from '../../components/0-formule-booster/formule-booster-spinners.vue';
import FilterOverview from '../../components/6-other/filter-overview.vue';
import ChartResult from '../../components/charts/chart-result.vue';
import ResearchTargetTabs from '../../components/research/research-target-tabs.vue';
import { ResultModule } from '../../store/modules/result-module';
import { ResearchModule } from '../../store/modules/research-module';
import { DashboardResult } from '@app/models';

@Component({
  components: {
    ChartResult,
    FilterOverview,
    ResearchTargetTabs,
    Spinners,
  },
})
export default class AllResults extends Vue {
  public currentPage = 1;
  public perPage = 10;
  public q = '';
  public debounceUpdateQuery = debounce(this.updateQuery, 200);

  private research = getModule(ResearchModule);
  private result = getModule(ResultModule);

  public get filtered() {
    return this.result.dashboard.filter(result => {
      const a = result.question.toLowerCase();
      const b = this.q.trim().toLowerCase();
      return a.indexOf(b) >= 0;
    });
  }

  public get results() {
    const start = (this.currentPage - 1) * this.perPage;
    const end = this.currentPage * this.perPage;

    return this.filtered
      .slice(start, end)
      .map(result => ({ ...result, icon: BlockElement.icon(result.type) }));
  }

  public get targetTabs() {
    return this.$targetTabs.filter(
      t =>
        this.research.currentResearch &&
        this.research.currentResearch.target.some(c => c.startsWith(t))
    );
  }

  created() {
    const { query } = this.$route;
    this.q = typeof query.q === 'string' ? query.q : '';
  }

  @Watch('currentPage')
  public goUp(e: any) {
    setTimeout(() => this.scrollUp(), 0);
  }

  @Watch('q')
  public watch() {
    const q = this.q || undefined;
    if (this.$route.query.q !== q) this.$router.replace({ query: { q } });
  }

  public get queryPlaceholder() {
    const searchFor = this.$t('search-for');
    const question = this.$t('question').toLowerCase();
    return `${searchFor} ${question}`;
  }

  public updateQuery(value: string) {
    this.q = value;
  }

  public open(result: DashboardResult) {
    this.$router.push({
      name: 'dashboard-result',
      params: { id: this.$route.params.id, eid: result.elementId },
    });
  }

  public scrollUp() {
    const container = this.$root.$el.querySelector('main');
    if (container && 'scrollTo' in container) container.scrollTo(0, 0);
  }
}
